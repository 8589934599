import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit"
import React from "react"
import Modal from "react-modal"
import { connect } from "react-redux"
import { Media } from "../../../media"
import { RootState } from "../../../state/createStore"
import {
    closeSearchModal, openSearchModal
} from "../../../state/slices/encyclopediaUI"
import CrossButton from "../../CoreUI/CrossButton"
import FAB from "../../CoreUI/FAB"
import SearchSettings from "./SearchSettings"


Modal.setAppElement("#___gatsby")

const customStyles = {
  content: {
    top: "0",
    left: "0",
    right: "auto",
    bottom: "auto",
    width: 300,
    // transform: "translate(0, 0)",
    boxShadow: `0 2px 4px 0px rgba(0,0,0,0.15)`,
    overflowY: "scroll",
    height: "100vh",
    borderRadius: 0,
    border: "none",
    background: "white",
    outline: "none",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: `rgba(0,0,0,0.2)`,
    zIndex: 999,
  },
}

interface SearchTabProps {
  currentFood: string
  closeSearchModal: ActionCreatorWithoutPayload<string>
  openSearchModal: ActionCreatorWithoutPayload<string>
  searchModalOpen: boolean
}

const SearchTab: React.FC<SearchTabProps> = ({
  currentFood,
  searchModalOpen,
  openSearchModal,
  closeSearchModal,
}) => {
  return (
    <>
      <Media lessThan="md" className="test">
        <FAB
          visible={!searchModalOpen}
          onClick={() => {
            openSearchModal()
          }}
        />
        <Modal
          className={{
            base: "UtilityModal__Content",
            afterOpen: "UtilityModal__Content--after-open",
            beforeClose: "UtilityModal__Content--before-close",
          }}
          overlayClassName={{
            base: "UtilityModal__Overlay",
            afterOpen: "UtilityModal__Overlay--after-open",
            beforeClose: "UtilityModal__Overlay--before-close",
          }}
          isOpen={searchModalOpen}
          style={customStyles}
          onRequestClose={() => closeSearchModal()}
          closeTimeoutMS={200}
        >
          <div className="px-4 pt-4 pb-12 max-h-screen h-screen relative">
            <CrossButton onClick={() => closeSearchModal()} />
            <SearchSettings currentFood={currentFood} />
          </div>
        </Modal>
      </Media>
      <Media greaterThanOrEqual="md" className="flex-1">
        <div className="relative bg-white border-grey-150 border-r justify-end flex flex-1 align-end">
          <div
            className="w-72 px-4 flex flex-col pb-12 pt-4"
            style={{ height: "calc(100vh - 50px)" }}
          >
            <SearchSettings currentFood={currentFood} />
          </div>
        </div>
      </Media>
    </>
  )
}

const mapDispatch = {
  closeSearchModal,
  openSearchModal,
}

interface SearchTabOwnProps {
  currentFood: string
}

const mapState = (state: RootState, ownProps: SearchTabOwnProps) => {
  return {
    searchModalOpen: state.encyclopediaUI.searchModalOpen,
    currentFood: ownProps.currentFood,
  }
}

export default connect(mapState, mapDispatch)(SearchTab)
