import { GatsbyBrowser } from "gatsby"
import FoodEntryLayout from "../components/Encyclopedia/Foods/Layout"
import FoodEncyclopediaLayout from "../components/Encyclopedia/Layout"
import React from "react"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  const encyclopediaEntry = /^\/encyclopedia\/foods\/[\s\S]+$/
  const foodEncyclopedia = /^\/encyclopedia\/foods[\/]?$/g

  if (encyclopediaEntry.test(props.location.pathname)) {
    return <FoodEntryLayout {...props}>{element}</FoodEntryLayout>
  }
  if (foodEncyclopedia.test(props.location.pathname)) {
    return <FoodEncyclopediaLayout {...props}>{element}</FoodEncyclopediaLayout>
  }
  return element
}
