import { createSlice } from "@reduxjs/toolkit"

interface quickSearchUIState {
  searchModalOpen: boolean
}

const initialState = {
  searchModalOpen: false,
}

const encyclopediaUI = createSlice({
  name: "quickSearchUI",
  initialState: initialState as quickSearchUIState,
  reducers: {
    openSearchModal(state) {
      state.searchModalOpen = true
    },
    closeSearchModal(state) {
      state.searchModalOpen = false
    },
  },
})

export const { openSearchModal, closeSearchModal } = encyclopediaUI.actions
export default encyclopediaUI.reducer
