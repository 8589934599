import React, { CSSProperties } from "react"
import { Transition } from "react-transition-group"

interface DropAndScaleProps {
  transitionIn: boolean
  className?: string
  styles?: CSSProperties
}

const DropAndScale: React.FC<DropAndScaleProps> = ({
  transitionIn,
  styles,
  className,
  children,
}) => {
  return (
    <Transition in={transitionIn} timeout={150} unmountOnExit>
      {state => (
        <div
          className={className}
          style={{
            willChange: "transform, opacity",
            ...styles,
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  )
}
export default DropAndScale

const defaultStyle = {
  transition: `transform 150ms ease-out, opacity 150ms ease-out`,
  opacity: 1,
}

const transitionStyles = {
  entering: { transform: "translateY(-7%) scale(0.9)", opacity: 0 },
  entered: { transform: "translateY(0px) scale(1)", opacity: 1 },
  exiting: { transform: "translateY(-7%) scale(0.9)", opacity: 0 },
  exited: { transform: "translateY(-7%) scale(0.9)", opacity: 0 },
}
