import React, { useEffect, useState } from "react"
import useBodyScroll from "../../../utils/hooks/useBodyScroll"
import useMounted from "../../../utils/hooks/useMounted"
import NavBar from "../../CoreUI/NavBar"
import AboutModal from "../../shared/AboutModal"
import SearchTab from "./SearchTab"

interface LayoutProps {
  data: any
}

const Layout: React.FC<LayoutProps> = ({ children, ...props }) => {
  const context = props.data.allSitePage.edges[0].node.context

  const childrenWithExtraProp = React.Children.map(children, child =>
    React.cloneElement(child as React.ReactElement<any>, { context: context })
  )

  const [entered, setEntered] = useState(false)
  const [childrenState, setChildrenState] = useState(childrenWithExtraProp)
  const mounted = useMounted()

  useEffect(() => {
    if (mounted) {
      setChildrenState(childrenWithExtraProp)
      setEntered(!entered)
    }
  }, [props.data])

  useBodyScroll(false)

  return (
    <>
      <div className="max-h-screen h-screen flex flex-col relative w-full max-w-screen bg-white">
        <AboutModal />
        <NavBar
          pageName="Food Encyclopedia"
          position="sticky"
          shadow={true}
          to="/encyclopedia/foods"
        />
        <div className="flex mx-auto w-full flex-1 justify-center h-full items-stretch">
          <SearchTab currentFood={context?.name || ""} />
          <div
            className="relative flex-1 bg-white overflow-y-scroll h-full"
            style={{ flexBasis: "775px" }}
          >
            <Contents>
              {childrenState}
            </Contents>
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout

const Contents = ({ children }: any) => {
  return (
    <div
      className="px-4 sm:px-8"
    >
      {children}
    </div>
  )
}
