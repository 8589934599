import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import scaleDataByPortion from "../../utils/nutrientData/scaleDataByPortion"
import {
  foods as FoodsNutrientData,
  metadata as MetaData,
} from "../../data/foodsNutrientData.json"
import foodsMetadata from "../../data/foodsMetadata.json"
import calcRangeAndMean from "../../utils/nutrientData/calcRangeAndMean"
import getNutrientNames from "../../utils/nutrientData/getNutrientNames"
import getFoodNames from "../../utils/nutrientData/getFoodNames"
import getNutrientUnits from "../../utils/nutrientData/getNutrientUnits"
import getFoodTypes from "../../utils/nutrientData/getFoodTypes"

interface NutrientDataState {
  foodsNutrientData: FoodsNutrientData
  portionScaledFoodsNutrientData: FoodsNutrientData
  nutrientNames: NutrientNames
  foodNames: FoodNames
  means: {
    [key: string]: number
  }
  ranges: {
    [key: string]: number
  }
  nutrientUnits: NutrientUnits
  portion: number
  foodTypes: {
    [key: string]: string
  }
}

const nutrientNames = getNutrientNames(MetaData)
const foodNames = getFoodNames(FoodsNutrientData)
const [ranges, means] = calcRangeAndMean(
  FoodsNutrientData,
  foodNames,
  nutrientNames
)
const nutrientUnits = getNutrientUnits(MetaData, nutrientNames)
const foodTypes = getFoodTypes(foodsMetadata.metadata)

const initialState: NutrientDataState = {
  foodsNutrientData: FoodsNutrientData,
  portionScaledFoodsNutrientData: FoodsNutrientData,
  nutrientNames: nutrientNames,
  foodNames: foodNames,
  means: means,
  ranges: ranges,
  nutrientUnits: nutrientUnits,
  portion: 100,
  foodTypes: foodTypes,
}

const nutrientDataSlice = createSlice({
  name: "foodsNutrientData",
  initialState: initialState,
  reducers: {
    updatePortion(state, action: PayloadAction<number>) {
      const newPortion = action.payload
      const portionScaledFoodsNutrientData = scaleDataByPortion(
        state.portionScaledFoodsNutrientData,
        state.portion,
        newPortion
      )

      state.portionScaledFoodsNutrientData = portionScaledFoodsNutrientData
      state.portion = newPortion
    },
  },
})

// export const setNotificationTime = createAsyncThunk(
//   "foodsNutrientData/updatePortionWithQuotas",
//   async (newPortion: number, thunkAPI): Promise<void> => {
//     await thunkAPI.dispatch(updatePortion)
//     await thunkAPI.dispatch(upda)
//     const state: RootState = thunkAPI.getState()
//     await NotificationHandler.syncSubNotifications(state.subs)
//   }
// )

export const { updatePortion } = nutrientDataSlice.actions
export default nutrientDataSlice.reducer
