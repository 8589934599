import reduceAgeValues from "./reduceAgeValues"

const reduceRDAULQuota = (
  quotasForNutrient: RDAULQuotaData,
  metrics: Metrics
): RDAULQuota => {
  //Check for ALs/RDAs/ULs and reduce by metrics
  let reducedRDAULQuota = {
    type: quotasForNutrient.type,
    units: quotasForNutrient.units,
  }
  //Determine age brackets for each quota type
  if (quotasForNutrient.rdas) {
    reducedRDAULQuota["rda"] = reduceAgeValues(quotasForNutrient.rdas, metrics)
  }
  if (quotasForNutrient.als) {
    reducedRDAULQuota["al"] = reduceAgeValues(quotasForNutrient.als, metrics)
  }
  if (quotasForNutrient.uls) {
    reducedRDAULQuota["ul"] = reduceAgeValues(quotasForNutrient.uls, metrics)
  }
  return reducedRDAULQuota
}
export default reduceRDAULQuota
