import { createSlice } from "@reduxjs/toolkit"

interface globalUIState {
  aboutModalOpen: boolean
}

const initialState = {
  aboutModalOpen: false,
}

const globalUISlice = createSlice({
  name: "globalUI",
  initialState: initialState as globalUIState,
  reducers: {
    openAboutModal(state) {
      state.aboutModalOpen = true
    },
    closeAboutModal(state) {
      state.aboutModalOpen = false
    },
  },
})

export const { openAboutModal, closeAboutModal } = globalUISlice.actions
export default globalUISlice.reducer
