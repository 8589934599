import calculateEnergyQuota from "./calcEnergyQuota"
import reduceAMDRQuota from "./reduceAMDRQuota"
import reduceRDAULQuota from "./reduceRDAULQuota"
import { quotas } from "../../../data/quotasData.json"
import reduceDGAQuota from "./reduceDGAQuota"

const calcQuotasFromMetrics = (quotasData: typeof quotas, metrics: Metrics) => {
  const personalQuotas = {}

  // Reduce raw quota data across age ranges into age specific values
  for (let nutrient in quotasData) {
    switch (quotasData[nutrient].type) {
      case "RDA_UL": {
        personalQuotas[nutrient] = reduceRDAULQuota(
          quotasData[nutrient] as RDAULQuotaData,
          metrics
        )
        break
      }
      case "AMDR": {
        personalQuotas[nutrient] = reduceAMDRQuota(
          quotasData[nutrient] as AMDRQuotaData,
          metrics
        )
        break
      }
      case "DGA": {
        personalQuotas[nutrient] = reduceDGAQuota(
          quotasData[nutrient] as DGAQuotaData,
          metrics
        )
        break
      }
      default: {
        personalQuotas[nutrient] = {
          type: null,
        }
      }
    }
  }

  //Additional unique nutrients
  personalQuotas["Energy"] = {
    type: "unique",
    units: quotasData["Energy"].units,
    eer: calculateEnergyQuota(metrics),
  }
  //@todo perform calculation for water quotas currently units don't match (grams vs litres)
  personalQuotas["Water"] = { value: 2, type: "unique", units: "litres" }

  return personalQuotas
}
export default calcQuotasFromMetrics
