import { combineReducers } from "@reduxjs/toolkit"
import searchTagsReducer from "../slices/searchTags"
import quotasReducer from "../slices/quotas"
import foodsNutrientDataReducer from "../slices/foodsNutrientData"
import quickSearchUIReducer from "../slices/quickSearchUI"
import globalUIReducer from "../slices/globalUI"
import encyclopediaUIReducer from "../slices/encyclopediaUI"

export default combineReducers({
  searchTags: searchTagsReducer,
  quotas: quotasReducer,
  foodsNutrientData: foodsNutrientDataReducer,
  quickSearchUI: quickSearchUIReducer,
  globalUI: globalUIReducer,
  encyclopediaUI: encyclopediaUIReducer,
})
