import { Link } from "gatsby"
import React from "react"
import { connect } from "react-redux"
import ChevronDown from "../../images/svgs/buttons/chevronDown.svg"
import ChevronRight from "../../images/svgs/buttons/chevronRight.svg"
import NavLogo from "../../images/svgs/icons/navLogo.svg"
import { Media } from "../../media"
import { RootState } from "../../state/createStore"
import {
    closeMetricsModal,
    openMetricsModal
} from "../../state/slices/quickSearchUI"
import NavMenu from "./NavMenu"

interface NavBarProps {
  closeMetricsModal: typeof closeMetricsModal
  openMetricsModal: typeof openMetricsModal
  metricsModalOpen: boolean
  pageName: string
  position?: "absolute" | "relative" | "sticky" | "fixed"
  shadow?: boolean
  to: string
  showMetrics?: boolean
}

const NavBar: React.FC<NavBarProps> = ({
  pageName,
  closeMetricsModal,
  openMetricsModal,
  metricsModalOpen,
  position = "relative",
  shadow = false,
  to,
  showMetrics = false,
}) => {
  return (
    <nav
      className={`flex items-center px-4 py-2 bg-white border-grey-150 z-50 border-b max-w-screen w-full top-0 ${position} ${
        shadow ? `shadow` : ``
      }`}
      style={{ zIndex: 999 }}
    >
      <div className="flex-1 flex items-center">
        <Link className="flex items-center" to="/">
          <NavLogo style={{ width: 34 }} />
          <Media greaterThanOrEqual="md">
            <h3 className="ml-2 text-xl text-grey-700 font-yoga leading-3">
              Whole Food Nutrition
            </h3>
          </Media>
          <Media lessThan="md">
            <h3 className="ml-2 text-xl text-grey-700 font-yoga leading-3">
              WFN
            </h3>
          </Media>
        </Link>
        <ChevronRight className="mx-4 fill-current text-grey-400" />
        <Link
          className="text-grey-400 flex items-center border-grey-150 py-2 group hover:underline transition duration-300 select-none"
          to={to}
        >
          <h3 className="text-sm text-grey-400 font-bold">{pageName}</h3>
        </Link>
      </div>
      {showMetrics ? (
        <button
          id="metric-options-button"
          onClick={() => {
            metricsModalOpen ? closeMetricsModal() : openMetricsModal()
          }}
          className="border border-transparent rounded-8 hover:border-grey-300 py-1 px-3 transition-border duration-300 ease-in-out flex items-center mr-4 focus-visible"
        >
          <ChevronDown className="ml-2" />
        </button>
      ) : null}
      <NavMenu />
    </nav>
  )
}

const mapDispatch = { openMetricsModal, closeMetricsModal }

const mapState = (state: RootState) => {
  return {
    metricsModalOpen: state.quickSearchUI.metricsModalOpen,
  }
}

export default connect(mapState, mapDispatch)(NavBar)
