import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import EncyclopediaIcon from "../../images/svgs/icons/encyclopedia.svg"
import HomeIcon from "../../images/svgs/icons/home.svg"
import QuickSearchIcon from "../../images/svgs/icons/quickSearchSmall.svg"
import useClickOutside from "../../utils/hooks/useClickOutside"
import useKeyPress from "../../utils/hooks/useKeyPress"
import DropAndScale from "../Transitions/DropAndScale"
import "./navMenu.css"

interface NavMenuProps {
  color?: "light" | "dark"
}

const NavMenu: React.FC<NavMenuProps> = () => {
  const ref = useRef(null)
  const containerRef = useRef(null)
  const firstLinkRef = useRef(null)
  const shiftTabPress = useKeyPress({
    targetKey: "Tab",
    ref: ref,
    modifier: "shift",
  })
  const tabPress = useKeyPress({
    targetKey: "Tab",
    ref: ref,
    excludeModifiers: true,
  })
  const [open, setOpen] = useState(false)
  useClickOutside(ref, () => setOpen(false))

  const toggleMenu = () => {
    setOpen(!open)
  }

  useEffect(() => {
    if (open) {
      firstLinkRef.current?.focus()
    }
  }, [open])

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && !ref.current?.contains(document.activeElement)) {
        setOpen(false)
      }
    })
  }, [shiftTabPress, ref])

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && !ref.current?.contains(document.activeElement)) {
        setOpen(false)
      }
    })
  }, [tabPress, ref])

  return (
    <div className="relative" ref={ref}>
      <button
        className={`focus-visible block py-2 px-3 hover:bg-kelp-hover transition duration-200 rounded-8`}
        onClick={() => toggleMenu()}
      >
        <div className={`${open ? `burger-menu--open` : `burger-menu`}`}>
          <div className="burger-menu__top" />
          <div className="burger-menu__middle" />
          <div className="burger-menu__bottom" />
        </div>
        {/* <MenuIcon
          className={`fill-current ${
            color === "dark" ? "text-grey-700" : "text-white"
          }`}
        /> */}
      </button>
      <DropAndScale transitionIn={open}>
        <nav
          className="absolute px-2 py-2 right-0 mt-0 mr-3 block rounded-md bg-white custom-shadow w-48 border border-grey-150 text-sm"
          ref={containerRef}
        >
          <Link
            to="/"
            className="block py-2 focus-visible flex items-center text-grey-500 hover:text-black hover:bg-feta-bg rounded-md px-3 group hover:text-feta-text"
            ref={firstLinkRef}
          >
            <HomeIcon className="mr-4 fill-current group-hover:text-feta-text text-grey-300" />
            Home
          </Link>
          <Link
            to="/quick-search"
            className="block py-2 focus-visible flex items-center text-grey-500 hover:text-black hover:bg-feta-bg rounded-md px-3 group hover:text-feta-text"
          >
            <QuickSearchIcon className="mr-4 fill-current group-hover:text-feta-text text-grey-300" />
            Quick Search
          </Link>
          <Link
            to="/encyclopedia/foods"
            className="block py-2 focus-visible flex items-center text-grey-500 hover:text-black hover:bg-feta-bg rounded-md px-3 group hover:text-feta-text"
          >
            <EncyclopediaIcon className="mr-4 fill-current group-hover:text-feta-text text-grey-300" />
            Encyclopedia
          </Link>
        </nav>
      </DropAndScale>
    </div>
  )
}
export default NavMenu
