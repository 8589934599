import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState
} from "react"
import useKeyPress from "../../utils/hooks/useKeyPress"

interface ColorMap {
  [key: string]: {
    text: string
    bg: string
  }
}

interface TagInputProps {
  tagOptions: Array<string>
  placeholder: string
  label?: string
  sublabel?: string
  tagColorMap?: ColorMap
  onTagChange: (tags: Array<string>) => void
}

const matchSearchTerm = (list: Array<string>, searchTerm: string) => {
  return list.filter(val =>
    val.toLowerCase().includes(searchTerm.toLowerCase())
  )
}

const filterTags = (list: Array<string>, tags: Array<string>) => {
  return list.filter(item => !tags.includes(item))
}

const TagInput: React.FC<TagInputProps> = ({
  tagOptions,
  placeholder,
  label,
  sublabel,
  tagColorMap,
  onTagChange,
}) => {
  const [focused, setFocused] = useState(false)
  const [tags, setTags] = useState<Array<string>>([])
  const [inputValue, setInputValue] = useState("")
  const [cursor, setCursor] = useState<number>(0)
  const [hovered, setHovered] = useState<string | undefined>(undefined)
  const [_, setSelected] = useState<
    React.SetStateAction<string | undefined>
  >(undefined)
  const [matchedTags, setMatchedTags] = useState(
    filterTags(matchSearchTerm(tagOptions, inputValue), tags)
  )

  const inputRef = useRef<HTMLInputElement>(null)

  const downPress = useKeyPress({
    targetKey: "ArrowDown",
    ref: inputRef,
    preventDefault: true,
  })
  const upPress = useKeyPress({
    targetKey: "ArrowUp",
    ref: inputRef,
    preventDefault: true,
  })
  const enterPress = useKeyPress({ targetKey: "Enter", ref: inputRef })

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setMatchedTags(
      filterTags(matchSearchTerm(tagOptions, e.currentTarget.value), tags)
    )
    setCursor(0)
    setSelected(undefined)
    setInputValue(e.currentTarget.value)
  }

  const handleSubmit = (tagToAdd: string) => {
    const newTags = [...tags, tagToAdd]
    setTags(newTags)
    onTagChange(newTags)
    setInputValue("")
  }

  useEffect(() => {
    setMatchedTags(matchSearchTerm(tagOptions, inputValue))
    setCursor(0)
  }, [tagOptions])

  useEffect(() => {
    if (matchedTags.length && downPress) {
      setCursor(prevState =>
        prevState < matchedTags.length - 1 ? prevState + 1 : 0
      )
    }
  }, [downPress])

  useEffect(() => {
    if (matchedTags.length && upPress) {
      setCursor(prevState =>
        prevState > 0 ? prevState - 1 : matchedTags.length - 1
      )
    }
  }, [upPress])

  useEffect(() => {
    if ((matchedTags.length && enterPress) || (matchedTags.length && hovered)) {
      handleSubmit(matchedTags[cursor])
    }
  }, [enterPress])

  const removeTag = (idx: number) => {
    const newTags = [...tags]
    newTags.splice(idx, 1)
    setTags(newTags)
    onTagChange(newTags)
    inputRef.current?.focus()
  }

  const showSuggestions = inputValue && focused && matchedTags.length

  return (
    <div className="relative">
      {label ? (
        <label className="block mb-1 text-grey-700">
          <b>{label}</b>
          <span className="text-grey-400 ml-1">{sublabel}</span>
        </label>
      ) : null}
      <div
        className={`relative border w-full flex rounded-md py-1 h-10 input-focus-within hide-scrollbar overflow-x-scroll ${
          focused ? `bg-grey-100 border-gossip` : `bg-grey-100 border-grey-150`
        }`}
      >
        <TagList
          tagLabels={tags}
          colorMap={tagColorMap}
          handleRemove={removeTag}
        />
        <input
          placeholder={tags.length ? `` : placeholder}
          value={inputValue}
          onChange={e => handleChange(e)}
          ref={inputRef}
          className={`flex-1 bg-transparent outline-none placeholder-grey-400 ${
            tags.length ? `px-2` : `px-3`
          }`}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </div>
      {showSuggestions ? (
        <div className="absolute mt-2 max-h-32 w-full z-50 bg-grey-100 border border-grey-150 rounded-md custom-shadow overflow-hidden">
          <ul role="listbox" className="">
            {matchedTags.map((matchedTag, i) => (
              <ListItem
                key={matchedTag + "-list-item-" + i}
                active={i === cursor}
                tag={matchedTag}
                setSelected={setSelected}
                setHovered={setHovered}
              />
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  )
}
export default TagInput

type ListItemProps = {
  tag: string
  active: boolean
  setSelected: Dispatch<SetStateAction<SetStateAction<string | undefined>>>
  setHovered: Dispatch<SetStateAction<string | undefined>>
}

const ListItem: React.FC<ListItemProps> = ({
  tag,
  active,
  setSelected,
  setHovered,
}) => {
  return (
    <li
      className={`py-2 rounded-sm px-4 cursor-pointer flex items-center ${
        active ? "bg-feta-bg text-feta-rich" : ""
      }`}
      onMouseDown={() => setSelected(tag)}
      onMouseEnter={() => setHovered(tag)}
      onMouseLeave={() => setHovered(undefined)}
    >
      {tag}
    </li>
  )
}

interface TagListProps {
  tagLabels: Array<string>
  colorMap: ColorMap | undefined
  handleRemove: (idx: number) => void
}

const TagList: React.FC<TagListProps> = ({
  tagLabels,
  colorMap,
  handleRemove,
}) => {
  return (
    <>
      {tagLabels.map((tag, idx) => {
        const colors = colorMap && colorMap[tag]
        ? colorMap[tag]
          : { text: "black", bg: "grey" }
        const { bg, text } = colors

        return (
          <button
            key={`${tag}-tag-${idx}`}
            className="rounded-md bg-grey-150 text-black px-2 ml-1 py-1 font-bold text-smm focus-visible"
            onClick={() => handleRemove(idx)}
            style={{
              backgroundColor: bg,
              color: text,
            }}
          >
            {tag.toUpperCase()}
          </button>
        )
      })}
    </>
  )
}
