import React from "react"
import { Transition } from "react-transition-group"
import SearchIcon from "../../images/svgs/icons/search.svg"

const defaultStyle = {
  transition: `transform 200ms, opacity 200ms ease`,
  opacity: 1,
}

const transitionStyles = {
  entering: { transform: "scale(0.7)", opacity: 0 },
  entered: { transform: "scale(1)", opacity: 1 },
  exiting: { transform: "scale(0.7)", opacity: 0 },
  exited: { transform: "scale(0.7)", opacity: 0 },
}

interface FABProps {
  onClick: () => void
  visible?: boolean
}

// Floating Action Button, Material Design: https://material.io/components/buttons-floating-action-button#usage
const FAB: React.FC<FABProps> = ({ onClick, visible = true }) => {
  return (
    <Transition in={visible} unmountOnExit timeout={200}>
      {state => (
        <button
          className="absolute rounded-full bg-grey-700 shadow-lg z-50 flex items-center justify-center focus-visible"
          style={{
            right: 25,
            bottom: 25,
            width: 55,
            height: 55,
            boxShadow: `0px 2px 4px 2px rgba(0,0,0,0.2), 0 1px 3px 0px rgba(0,0,0,0.3)`,
            ...defaultStyle,
            ...transitionStyles[state],
          }}
          onClick={() => {
            onClick()
          }}
        >
          <SearchIcon
            className="stroke-current text-white"
            style={{ height: 19, width: 19 }}
          />
        </button>
      )}
    </Transition>
  )
}
export default FAB
