import React, { ReactElement, useState } from "react"
import "./tooltip.css"

interface TooltipProps {
  direction?: "top" | "right" | "bottom" | "left"
  content: string | ReactElement
  delay?: number
  maxWidth?: number
}

const Tooltip: React.FC<TooltipProps> = props => {
  let timeout: NodeJS.Timeout | null = null
  const [showTooltip, setShowTooltip] = useState(false)

  const showTip = () => {
  }

  const hideTip = () => {
    if (timeout) {
      clearInterval(timeout)
    }
    setShowTooltip(false)
  }

  return (
    <div className="tooltip-wrapper">
      <div
        onMouseEnter={showTip}
        onMouseLeave={hideTip}
        tabIndex={0}
        onFocus={() => setShowTooltip(true)}
        onBlur={() => setShowTooltip(false)}
      >
        {props.children}
      </div>
      {showTooltip && (
        <div
          role="dialog"
          aria-live="polite"
          className={`tooltip-tip z-50 ${props.direction || "top"} ${
            showTooltip ? `tooltip-visible` : `tooltip-invisible`
          } ${props.maxWidth ? `defined-width` : ``}`}
          style={{
            width: props.maxWidth ? props.maxWidth! : undefined,
            whiteSpace: props.maxWidth ? "normal" : "pre",
          }}
        >
          {props.content}
        </div>
      )}
    </div>
  )
}
export default Tooltip
