import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { quotas } from "../../data/quotasData.json"
import {
    getItem,
    setItem
} from "../../utils/localStorage"
import calcQuotasFromMetrics from "../../utils/quotas/calculation/calcQuotasFromMetrics"

type QuotasState = {
  metrics: Metrics
  userQuotas: UserPersonalQuotas
  quotasData: typeof quotas
}

const defaultMetrics: Metrics = {
  age: 20,
  weight: 70,
  height: 160,
  sex: "male",
}

const getMetricsInStorage = () => {
  let metrics = getItem("metrics")

  if (metrics === null) {
    setItem("metrics", defaultMetrics)
    return defaultMetrics
  }
  return metrics as Metrics
}

const setMetricsInStorage = (newMetrics: Metrics) => {
  setItem("metrics", newMetrics)
}

const initialState: QuotasState = {
  metrics: getMetricsInStorage(),
  userQuotas: calcQuotasFromMetrics(quotas, defaultMetrics),
  quotasData: quotas,
}

const quotasSlice = createSlice({
  name: "quotas",
  initialState: initialState,
  reducers: {
    updateMetrics(state, action: PayloadAction<Metrics>) {
      const newMetrics = action.payload

      const userQuotas: UserPersonalQuotas = calcQuotasFromMetrics(
        state.quotasData,
        newMetrics
      )

      state.userQuotas = userQuotas
      state.metrics = newMetrics
      setMetricsInStorage(newMetrics)
    },
  },
})

export const { updateMetrics } = quotasSlice.actions
export default quotasSlice.reducer
