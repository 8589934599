import findQuotaBracket from "../findQuotaBracket"

interface AgeValues {
  [key: string]: number | string
}

// Reduce the full quota data into age specific values
const reduceAgeValues = (ageValues: AgeValues, metrics: Metrics) => {
  //Go through and get the appropriate age
  const quotaBrackets = Object.keys(ageValues)

  if ("all" in quotaBrackets) {
    //Escape for a universal quota (metric independent)
    return ageValues["all"]
  } else {
    //Get appropriate age bracket based on metrics (ie 2, 5, 25f, 22m)
    const bracketSelector = findQuotaBracket(quotaBrackets, metrics)
    return ageValues[bracketSelector]
  }
}
export default reduceAgeValues
