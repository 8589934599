import { DefaultTheme } from "styled-components"

interface Colors {
  common: {
    black: string
    white: string
  }
  primary: Color
  grey: {
    50?: string
    100?: string
    200?: string
    300?: string
    400?: string
    500?: string
    700?: string
    800?: string
  }
}

declare module "styled-components" {
  export interface DefaultTheme {
    spacing: (multiplier: number) => string
    shape: {
      borderRadius: {
        lg: string
      }
    }
    colors: Colors
    shadows: {
      6: string
    }
    typography: {
      fonts: {
        yogaSans: "Yoga Sans"
      }
      sizes: {
        normal: string
        large: string
      }
      h1: TypeSettings
      subtitle1: TypeSettings
    }
  }
}

interface Color {
  main: string
}

interface TypeSettings {
  fontFamily: string
  fontSize: string
  lineHeight: string
  fontWeight: string
  letterSpacing: string
  color?: string
}

const basePixelGrid = 8

const colors = {
  common: { black: "#000", white: "#fff" },
  primary: { main: "#B4D05F" },
  grey: {
    50: "#F8F8F8",
    100: "#F1F1F1",
    400: "#A4A4A4",
    700: "#454545",
    800: "#414141",
  },
}

export const theme: DefaultTheme = {
  spacing: multiplier => `${multiplier * basePixelGrid}px`,
  shape: {
    borderRadius: {
      lg: "50px",
    },
  },
  colors,
  shadows: {
    6: "0 2px 12px 0 rgba(0,0,0,0.12)",
  },
  typography: {
    fonts: {
      yogaSans: "Yoga Sans",
    },
    sizes: {
      normal: "16px",
      large: "22px",
    },
    h1: {
      fontFamily: "DM Sans, sans-serif",
      fontWeight: "700",
      lineHeight: "120%",
      fontSize: "3.5rem",
      letterSpacing: "-0.5px",
      color: colors.grey[800],
    },
    subtitle1: {
      fontFamily: "DM Sans, sans-serif",
      fontWeight: "400",
      lineHeight: "150%",
      fontSize: "1.125rem",
      letterSpacing: "0em",
      color: colors.grey[400],
    },
  },
}
