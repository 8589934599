import { findAgeBracketWithSexes } from "./findAgeBrackets"

const findQuotaBracket = (
  brackets: Array<string>,
  metrics: Metrics
): string => {
  if (!brackets.length) {
    return ""
  }

  //@todo This could easily break without testing
  const decomposedBrackets: Array<[number, string]> = brackets.map(
    (bracket: string) => {
      let bracketAge, bracketSex
      let bracketAgeRegex = /^\d+/.exec(bracket) || null
      let bracketSexRegex = /[mf]$/.exec(bracket) || null
      if (bracketAgeRegex) {
        bracketAge = parseInt(bracketAgeRegex[0])
      } else {
        bracketAge = 0
      }
      if (bracketSexRegex) {
        bracketSex = bracketSexRegex[0]
      } else {
        bracketSex = ""
      }
      return [bracketAge, bracketSex]
    }
  )

  //@todo Not sure if a, b are right away around
  //Parse the brackets ie '25f' -> [25, f] or '20' -> [20, null]
  decomposedBrackets.sort((a, b) => {
    return a[0] - b[0]
  })

  //Find appropriate bracket for age/sex
  let bracket = findAgeBracketWithSexes(decomposedBrackets, metrics)

  const recomposedBracket = decomposedBrackets[bracket].join("")

  return recomposedBracket
}
export default findQuotaBracket
