import { useEffect } from "react"

const useClickOutside = (
  ref: React.RefObject<HTMLElement>,
  onClickOutside: Function
) => {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current!.contains(event.target as Node | null)) {
        onClickOutside()
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, onClickOutside])
}
export default useClickOutside
