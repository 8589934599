import { useEffect } from "react"
const windowDefined = typeof window !== `undefined`

const useBodyScroll = (shouldBodyScroll: boolean = true) => {
  if (windowDefined) {
    document.body.style.overflowY = shouldBodyScroll ? "scroll" : "hidden"
  }
  useEffect(() => {
    if (windowDefined) {
      document.body.style.overflowY = shouldBodyScroll ? "scroll" : "hidden"
    }
    return () => {
      if (windowDefined) {
        document.body.style.overflowY = "auto"
      }
    }
  })
}
export default useBodyScroll
