import { PageProps } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import "../../css/index.css"
import { metadata } from "../../data/foodsMetadata.json"
import parseFoodNameToPath from "../../utils/parseFoodNameToPath"
import NavBar from "../CoreUI/NavBar"
import AboutModal from "../shared/AboutModal"
import EncyclopediaSearchBar from "./Searchbar"
import TabNavigator from "./TabNavigator"

type LayoutProps = PageProps

const suggestions = metadata.map((item, idx) => {
  return {
    id: idx,
    name: item.name,
    type: "food",
    link: `/encyclopedia/foods/${parseFoodNameToPath(item.name)}`,
  }
})

const tabs = [
  { label: "foods", to: "/encyclopedia/foods" },
  { label: "nutrients", to: "/encyclopedia/foods" },
]

const Layout: React.FC<LayoutProps> = ({ children, ...props }) => {
  let initialTab = tabs[0]
  tabs.forEach(tab => {
    if (props.location.pathname.includes(tab.label)) {
      initialTab = tab
    }
  })

  const backgroundFixedImageStack = [
    // Haha
    (props.data as {foodBannerImage: {childImageSharp: {fixed: string}}}).foodBannerImage.childImageSharp.fixed,
    `linear-gradient(180deg, rgba(188,196,113,0.65) 0%, rgba(84,123,47,0.65) 100%)`,
  ].reverse()

  return (
    <>
      <div className="relative bg-kidnapper-light min-h-screen">
        <AboutModal />
        <NavBar
          pageName="Encyclopedia"
          position="sticky"
          shadow={true}
          to="/encyclopedia"
        />
        <BackgroundImage
          Tag={`section`}
          id={`test`}
          className={`flex flex-col items-center relative w-full z-50`}
          fixed={backgroundFixedImageStack}
          style={{
            backgroundRepeat: "repeat-x",
          }}
          backgroundColor={`#6D902F`}
        >
          <div className="w-3xl px-6 py-20">
            <p
              className="text-deco text-base font-bold text-center mx-auto"
              style={{
                maxWidth: 500,
                textShadow: `0px 0px 5px rgba(0,0,0,0.45)`,
              }}
            >
              Included in our encyclopedia are entries for various foods and
              nutrients, each describing some key facts and points of interest.
            </p>
            <div className="border-b border-deco w-48 mt-3 mb-5 mx-auto" />
            <div className="mx-auto" style={{ maxWidth: 500 }}>
              <EncyclopediaSearchBar
                placeholder="Search encyclopedia"
                onSubmit={() => {}}
                suggestions={suggestions}
              />
            </div>
          </div>
        </BackgroundImage>
        <div className="bg-white pt-3 border-b border-t border-grey-150 shadow-sm">
          <TabNavigator initialSelection={initialTab} tabOptions={tabs} />
        </div>

        {children}
      </div>
    </>
  )
}
export default Layout
