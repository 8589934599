import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit"
import React from "react"
import Modal from "react-modal"
import { connect } from "react-redux"
import AboutLogo from "../../images/svgs/aboutLogo.svg"
import DribbleLogo from "../../images/svgs/icons/dribble.svg"
import GithubLogo from "../../images/svgs/icons/github.svg"
import { RootState } from "../../state/createStore"
import { closeAboutModal } from "../../state/slices/globalUI"
import CrossButton from "../CoreUI/CrossButton"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: 12,
    border: `1px solid lightgrey`,
    boxShadow: `0 2px 4px 0px rgba(0,0,0,0.15)`,
  },
  overlay: {
    backgroundColor: `rgba(0,0,0,0.2)`,
    zIndex: 999,
  },
}

interface AboutModalProps {
  open: boolean
  closeAboutModal: ActionCreatorWithoutPayload<string>
}

const AboutModal: React.FC<AboutModalProps> = ({ open, closeAboutModal }) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => closeAboutModal()}
      style={customStyles}
      closeTimeoutMS={400}
    >
      <CrossButton onClick={() => closeAboutModal()} />
      <div className="px-12 py-8">
        <AboutLogo className="mx-auto" />
        <h1 className="font-yoga font-bold text-grey-700 text-2xl">
          Whole Food Nutrition
        </h1>
        <h3 className="text-grey-500 font-bold text-center mb-6">v3.0.0</h3>
        <h3 className="text-smm font-bold">DESIGN & ENGINEERING</h3>

        <div className="flex mt-1">
          <h3 className="text-md text-grey-600 flex-1">Miles Crighton</h3>
          <a
            className="flex items-center justify-center rounded-full bg-sushi-light h-6 w-6 mr-1 hover:bg-sushi-dark transition duration-300 ease-in-out"
            href="https://github.com/miles-crighton"
            target="_blank"
            style={{ width: 26, height: 26 }}
          >
            <GithubLogo
              className="fill-current text-white"
              style={{ height: 15, width: 15 }}
            />
          </a>
          <a
            className="flex items-center justify-center rounded-full bg-sushi-light h-6 w-6 hover:bg-sushi-dark transition duration-300 ease-in-out"
            href="https://dribbble.com/MilesC"
            target="_blank"
            style={{ width: 26, height: 26 }}
          >
            <DribbleLogo style={{ height: 15, width: 15 }} />
          </a>
        </div>
        <h3 className="text-smm font-bold mt-4">DATA SOURCES</h3>
        <h3 className="text-sm text-grey-600 block">
          Food Nutrient Data:{" "}
          <a
            className="text-sm text-curiousblue not-italic hover:underline float-right"
            target="_blank"
            href="https://fdc.nal.usda.gov/"
          >
            USDA
          </a>
        </h3>

        <h3 className="text-sm text-grey-600">
          Food Production Data:{" "}
          <a
            className="text-sm text-curiousblue hover:underline float-right"
            target="_blank"
            href="http://www.fao.org/faostat/en/#home"
          >
            FAO
          </a>
        </h3>
      </div>
    </Modal>
  )
}

const mapDispatch = {
  closeAboutModal,
}

const mapState = (state: RootState) => {
  return {
    open: state.globalUI.aboutModalOpen,
  }
}

export default connect(mapState, mapDispatch)(AboutModal)
