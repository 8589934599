import reduceAgeValues from "./reduceAgeValues"

const reduceAMDRQuota = (
  quotasForNutrient: AMDRQuotaData,
  metrics: Metrics
): AMDRQuota => {
  let reducedAMDRQuota = {
    type: quotasForNutrient.type,
    units: quotasForNutrient.units,
  }

  if (quotasForNutrient.amdrs) {
    reducedAMDRQuota["amdr"] = reduceAgeValues(
      quotasForNutrient["amdr"],
      metrics
    )
  }
  return reducedAMDRQuota
}
export default reduceAMDRQuota
