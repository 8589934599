import { RefObject, useState, useEffect } from "react"

type Modifier = "ctrl" | "alt" | "shift" | null

const modifierMap = {
  ctrl: "ctrlKey",
  alt: "altKey",
  shift: "shiftKey",
}

interface KeyPressArgs {
  targetKey: string
  ref: RefObject<HTMLElement>
  excludeModifiers?: boolean
  modifier?: Modifier
  preventDefault?: boolean
}

const useKeyPress = ({
  targetKey,
  ref,
  excludeModifiers = false,
  modifier = null as Modifier,
  preventDefault = false,
}: KeyPressArgs) => {
  const [keyPressed, setKeyPressed] = useState(false)

  const downHandler = (e: KeyboardEvent) => {
    if (excludeModifiers) {
      const modifierKeys = Object.keys(modifierMap)
      let modifierActive = false
      for (let modifierKey of modifierKeys) {
        if (e[modifierMap[modifierKey]]) {
          modifierActive = true
        }
      }
      if (e.key === targetKey && !modifierActive) {
        preventDefault ? e.preventDefault() : null
        setKeyPressed(true)
      }
    } else if (
      e.key === targetKey &&
      (modifier ? e[modifierMap[modifier]] : true)
    ) {
      preventDefault ? e.preventDefault() : null
      setKeyPressed(true)
    }
  }

  const upHandler = (e: KeyboardEvent) => {
    preventDefault ? e.preventDefault() : null
    if (e.key === targetKey && (modifier ? e[modifierMap[modifier]] : true)) {
      setKeyPressed(false)
    }
  }

  useEffect(() => {
    ref.current?.addEventListener("keydown", downHandler)
    ref.current?.addEventListener("keyup", upHandler)

    return () => {
      ref.current?.removeEventListener("keydown", downHandler)
      ref.current?.removeEventListener("keyup", upHandler)
    }
  })

  return keyPressed
}
export default useKeyPress
