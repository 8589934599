const scaleDataByPortion = (
  foodData: FoodsNutrientData,
  currentPortion: number,
  newPortion: number
): FoodsNutrientData => {
  let newData = { ...foodData }

  if (currentPortion !== newPortion) {
    for (let [key, value] of Object.entries(foodData)) {
      newData[key] = {}
      for (let [key2, value2] of Object.entries(value)) {
        //Scale values to portion size (3sf)
        newData[key][key2] = parseFloat(
          (value2 * (newPortion / currentPortion)).toPrecision(3)
        )
      }
    }
  }
  return newData
}
export default scaleDataByPortion
