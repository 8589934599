const windowAvailable = typeof window !== `undefined`
export let supportsLocalStorage = windowAvailable
  ? "localStorage" in window
  : false

export const getItem = (itemKey: string): Object | null => {
  if (supportsLocalStorage) {
    if (localStorage.getItem(itemKey)) {
      return JSON.parse(localStorage.getItem(itemKey)!)
    }
  }
  return null
}

export const setItem = (itemKey: string, value: any): boolean => {
  if (supportsLocalStorage) {
    localStorage.setItem(itemKey, JSON.stringify(value))
    return true
  }
  return false
}
