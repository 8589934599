export const calcRangeAndMean = (
  data: FoodsNutrientData,
  foods: Array<string>,
  nutrients: Array<string>
): [DataRanges, DataMeans] => {
  const range = {}
  const mean = {}
  //For each nutrient calculate range and mean
  for (let i = 0; i < nutrients.length; i++) {
    //Consilidate a single nutrient
    let all = foods.map(food => {
      return data[food][nutrients[i]]
    })
    //Calculate sum of nutrient values
    let total = all.reduce((total, value) => {
      return total + value
    })
    //Generate range/mean
    range[nutrients[i]] = Math.max(...all) - Math.min(...all)
    mean[nutrients[i]] = total / all.length
  }
  return [range, mean]
}
export default calcRangeAndMean
