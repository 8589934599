import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface quickSearchUIState {
  expandedFoodID: null | string
  cachedFoodOrder: Array<string>
  metricsModalOpen: boolean
  utilityModalOpen: boolean
  searchMode: "nutrient" | "food"
  searchValue: string
}

const initialState = {
  expandedFoodID: null,
  cachedFoodOrder: [],
  metricsModalOpen: false,
  utilityModalOpen: false,
  searchMode: "nutrient",
  searchValue: "",
}

const quickSearchUISlice = createSlice({
  name: "quickSearchUI",
  initialState: initialState as quickSearchUIState,
  reducers: {
    setSearchValue(state, action: PayloadAction<string>) {
      state.searchValue = action.payload
    },
    toggleSearchMode(state) {
      if (state.searchMode === "nutrient") {
        state.searchMode = "food"
      } else {
        state.searchMode = "nutrient"
      }
    },
    openExpandedFoodInfo(state, action: PayloadAction<string>) {
      state.expandedFoodID = action.payload
    },
    closeExpandedFoodInfo(state) {
      state.expandedFoodID = null
    },
    openMetricsModal(state) {
      state.metricsModalOpen = true
    },
    closeMetricsModal(state) {
      state.metricsModalOpen = false
    },
    openUtilityModal(state) {
      state.utilityModalOpen = true
    },
    closeUtilityModal(state) {
      state.utilityModalOpen = false
    },
    cacheFoodOrder(state, action: PayloadAction<Array<string>>) {
      state.cachedFoodOrder = action.payload
    },
  },
})

export const {
  setSearchValue,
  toggleSearchMode,
  cacheFoodOrder,
  openMetricsModal,
  closeMetricsModal,
  openUtilityModal,
  closeUtilityModal,
  openExpandedFoodInfo,
  closeExpandedFoodInfo,
} = quickSearchUISlice.actions
export default quickSearchUISlice.reducer
