import { Link } from "gatsby"
import React, { useState } from "react"
import foodFacts from "../../../data/foodFacts.json"
import SearchIcon from "../../../images/svgs/icons/search.svg"
import parseFoodNameToPath from "../../../utils/parseFoodNameToPath"
import TagInput from "../../CoreUI/TagInput"

const tagColorMap = {
  Legumes: { text: "#A27D47", bg: "#F9EBD6" },
  Fruits: { text: "#CE4A62", bg: "#FAD9DF" },
  Grains: { text: "#9C8230", bg: "#F7EDCE" },
  Veg: { text: "#CB6F28", bg: "#FFE5D0" },
  Seeds: { text: "#8C9427", bg: "#ECEFCC" },
  Nuts: { text: "#90582B", bg: "#F8E1CD" },
  Fungi: { text: "#919563", bg: "#EAECD9" },
}

const labelValueMap = {
  Nuts: "Nut",
  Veg: "Vegetable",
  Legumes: "Legume",
  Seeds: "Seed",
  Fruits: "Fruit",
  Grains: "Grain",
  Fungi: "Fungus",
}

const foodNamesWithTypes = foodFacts.map(foodObject => {
  return { name: foodObject.name, type: foodObject.type }
})

const matchSearchTerm = (list: Array<string>, searchTerm: string) => {
  return list.filter(val =>
    val.toLowerCase().includes(searchTerm.toLowerCase())
  )
}

const matchSearchTags = (
  list: Array<{ name: string; type: string }>,
  tags: Array<string>
) => {
  return tags.length
    ? list.filter(item => tags.includes(item.type)).map(item => item.name)
    : list.map(item => item.name)
}

interface SearchSettingsProps {
  currentFood: string
}

const SearchSettings: React.FC<SearchSettingsProps> = ({ currentFood }) => {
  const [searchValue, setSearchValue] = useState("")
  const [searchTags, setSearchTags] = useState<Array<string>>([])
  let searchResults = matchSearchTags(foodNamesWithTypes, searchTags)
  searchResults = matchSearchTerm(searchResults, searchValue)

  const handleNewTags = (newTags: Array<string>) => {
    setSearchTags(newTags.map(tag => labelValueMap[tag]))
  }

  return (
    <>
      <div className="flex font-bold py-3 items-center">
        <div className="bg-sushi-light w-1 h-4 mr-3" />
        SEARCH
      </div>
      <div className="relative mb-4 w-full">
        <div className="absolute flex items-center pl-3 h-full">
          <SearchIcon style={{ height: 15 }} />
        </div>
        <input
          className={`w-full flex-shrink-0 transition-bg duration-150 ease-in-out focus:border-gossip border-grey-150 border placeholder-grey-400 h-10 pl-10 text-grey-700 outline-none input-focus rounded-md bg-grey-100`}
          placeholder="Find a food"
          onChange={e => setSearchValue(e.target.value)}
        />
      </div>
      <TagInput
        onTagChange={tags => handleNewTags(tags)}
        tagOptions={[
          "Nuts",
          "Veg",
          "Legumes",
          "Seeds",
          "Fruits",
          "Grains",
          "Fungi",
        ]}
        tagColorMap={tagColorMap}
        placeholder="Filter by food group"
        label="Filter"
        sublabel="(category)"
      />
      <div className="flex justify-between border-grey-150 items-center py-1 mt-4">
        <h2 className="inline text-base text-black font-bold">Results</h2>
        <h3 className="text-grey-400">({searchResults.length})</h3>
      </div>
      <ul
        className="overflow-y-scroll grey-scroll overflow-x-hidden"
        role="list"
        style={{ maxHeight: "60vh" }}
      >
        {searchResults.length <= 0 ? "No results" : null}
        {searchResults.map(foodName => (
          <Link
            role="listitem"
            to={`/encyclopedia/foods/${parseFoodNameToPath(foodName)}`}
            className={`py-2 rounded-md transition duration-150 block ${
              currentFood === foodName
                ? `bg-transparent text-black hover:bg-feta-bg hover:text-feta-text`
                : `bg-transparent text-grey-650 hover:bg-feta-bg hover:text-feta-text`
            }`}
            key={foodName + "-result-item"}
          >
            <div
              className={`flex items-center transition duration-150 ease-in-out ${
                currentFood === foodName ? `translate-x-4 transform` : ``
              }`}
            >
              <div
                className={`rounded-full w-1 h-1 mr-2 ${
                  currentFood === foodName ? `bg-sushi-light` : `bg-transparent`
                }`}
              />

              {foodName}
            </div>
          </Link>
        ))}
      </ul>
    </>
  )
}
export default SearchSettings
