const getNutrientUnits = (metaData: MetaData, nutrientNames: Array<string>) => {
  let nutrientUnits: NutrientUnits = {}
  nutrientNames.forEach((nutrient: string) => {
    if ("units" in metaData) {
      if (nutrient in metaData.units) {
        nutrientUnits[nutrient] = metaData.units[nutrient]
      } else {
        nutrientUnits[nutrient] = null
      }
    }
  })
  return nutrientUnits
}
export default getNutrientUnits
