import { Link } from "gatsby"
import React, { useState } from "react"
import Tooltip from "../CoreUI/Tooltip"

interface TabOption {
  label: string
  to: string
}

interface TabNavigatorProps {
  initialSelection: TabOption
  tabOptions: Array<TabOption>
}

const TabNavigator: React.FC<TabNavigatorProps> = ({
  initialSelection,
  tabOptions,
}) => {
  const [selection] = useState(initialSelection)
  const [hovered, setHovered] = useState<null | string>(null)

  return (
    <div className="flex justify-center items-end">
      {tabOptions.map(tabOption => {
        const content = (
          <Link
            key={`${tabOption.label}-tabNav-link`}
            className={`font-bold text-base pt-2 focus-visible text-center group transition duration-300 ${
              selection.label === tabOption.label
                ? hovered === tabOption.label || hovered === null
                  ? `text-grey-650`
                  : `text-grey-500`
                : `text-grey-400`
            }`}
            to={tabOption.to}
            onMouseEnter={() => setHovered(tabOption.label)}
            onMouseLeave={() => setHovered(null)}
          >
            <span className="mx-6">{tabOption.label.toUpperCase()}</span>
            <div
              className={`h-1 w-10 mt-1 mx-auto rounded-t-lg transition duration-300 ${
                selection.label === tabOption.label
                  ? hovered === tabOption.label || hovered === null
                    ? `bg-conifer-rich`
                    : `bg-conifer-light`
                  : `bg-transparent group-hover:bg-grey-125`
              }`}
            />
          </Link>
        )

        if (tabOption.label === "nutrients") {
          return (
            <Tooltip
              content="Coming soon"
              key={`${tabOption.label}-tabNav-link`}
            >
              {content}
            </Tooltip>
          )
        }
        return content
      })}
    </div>
  )
}
export default TabNavigator
