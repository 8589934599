type metadata = Array<{
  id: number
  name: string
  type: string
  associatedExternalIDs: {
    USDA: number
    FAO?: undefined
  }
  productionDataAvailable: boolean
  nutrientDataAvailable: boolean
}>

const getFoodTypes = (metadata: metadata) => {
  let foodTypes = {}
  metadata.forEach(foodObject => (foodTypes[foodObject.name] = foodObject.type))
  return foodTypes
}
export default getFoodTypes
