import arrayStringToNum from "../arrayStringToNum"
import { findAgeBracket } from "../findAgeBrackets"

/**
 * Calculations from: https://www.nap.edu/read/11537/chapter/8
 * (Dietary Reference Intakes: The Essential Guide to Nutrient Requirements (2006))
 * @param metrics
 */
const calculateEnergyQuota = (metrics: Metrics) => {
  let EER //Estimate energy requirements (kcal / day)
  let PA = 1 //Physical activity coefficient
  const heightInMetres = metrics.height / 100

  if (metrics.age <= 3) {
    //Infants and young children
    const ageEnergyDepositions = {
      0.25: 175,
      0.5: 56,
      1: 22,
      3: 20,
    }

    const ageRange = findAgeBracket(
      arrayStringToNum(Object.keys(ageEnergyDepositions)),
      metrics.age
    )

    EER = 89 * metrics.weight - 100 + ageEnergyDepositions[ageRange]
  } else if (metrics.age <= 18) {
    //Children and adolescents
    const ageEnergyDepositions = {
      8: 20,
      18: 25,
    }

    const ageRange = findAgeBracket(
      arrayStringToNum(Object.keys(ageEnergyDepositions)),
      metrics.age
    )

    if (metrics.sex === "male") {
      EER =
        88.5 -
        61.9 * metrics.age +
        PA * (26.7 * metrics.weight + 903 * heightInMetres) +
        ageEnergyDepositions[ageRange]
    } else if (metrics.sex === "female") {
      EER =
        135.3 -
        30.8 * metrics.age +
        PA * (10 * metrics.weight + 934 * heightInMetres) +
        ageEnergyDepositions[ageRange]
    }
  } else {
    //Adults
    if (metrics.sex === "male") {
      EER =
        662 -
        9.53 * metrics.age +
        PA * (15.91 * metrics.weight + 539.6 * heightInMetres)
    } else if (metrics.sex === "female") {
      EER =
        354 -
        6.91 * metrics.age +
        PA * (9.36 * metrics.weight + 726 * heightInMetres)
    }
  }

  return EER
}
export default calculateEnergyQuota
