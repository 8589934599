import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type SearchTagsState = Array<string>

const searchTagsSlice = createSlice({
  name: "searchTags",
  initialState: [] as SearchTagsState,
  reducers: {
    addSearchTag(state, action: PayloadAction<string>) {
      if (state.length < 4) {
        state.push(action.payload)
      }
    },
    removeSearchTag(state, action: PayloadAction<string>) {
      const idx = state.findIndex(element => element === action.payload)
      if (idx > -1) {
        state.splice(idx, 1)
      }
    },
  },
})

export const { addSearchTag, removeSearchTag } = searchTagsSlice.actions
export default searchTagsSlice.reducer
