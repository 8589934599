import reduceAgeValues from "./reduceAgeValues"

const reduceDGAQuota = (
  quotasForNutrient: DGAQuotaData,
  metrics: Metrics
): DGAQuota => {
  //Check for AMDRs/DGAs and reduce by metrics
  let reducedDGAQuota = {
    type: quotasForNutrient.type,
    units: quotasForNutrient.units,
  }
  if (quotasForNutrient.dgas) {
    reducedDGAQuota["dga"] = reduceAgeValues(quotasForNutrient["dgas"], metrics)
  }
  return reducedDGAQuota
}
export default reduceDGAQuota
