import { Link, navigate } from "gatsby"
import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState
} from "react"
import SearchIcon from "../../images/svgs/icons/search.svg"
import useKeyPress from "../../utils/hooks/useKeyPress"

interface SearchBarProps {
  placeholder: string
  onSubmit: (value: string) => void
  suggestions: Array<itemType>
  disabled?: boolean
}

type itemType = {
  id: number
  name: string
  icon?: string
  type: string
  link: string
}

const itemTypeStyles = {
  food: {
    bg: "#FFDEDE",
    text: "#B13D3D",
  },
}

type ListItemProps = {
  item: itemType
  active: boolean
  setSelected: Dispatch<SetStateAction<SetStateAction<itemType | undefined>>>
  setHovered: Dispatch<SetStateAction<itemType | undefined>>
  searchValue: string
}

const ListItem: React.FC<ListItemProps> = React.memo(
  ({ item, active, setSelected, setHovered, searchValue }) => {
    const color = itemTypeStyles[item.type].text || "black"
    const bg = itemTypeStyles[item.type].bg || "grey"

    const regExp = new RegExp(searchValue, "gi")

    const searchSplit = item.name.split(regExp)
    const values = [...item.name.matchAll(regExp)]
    const searchSplitTitleCase = searchSplit

    return (
      <Link to={item.link}>
        <li
          className={`py-1 text-base rounded-sm px-2 rounded-full cursor-pointer flex items-center ${
            active ? "bg-grey-125" : "bg-transparent"
          }`}
          onMouseDown={() => setSelected(item)}
          onMouseEnter={() => setHovered(item)}
          onMouseLeave={() => setHovered(undefined)}
        >
          <div
            className="rounded-full py-1 bg-grey-200 w-20 mr-4 text-center text-xsm font-bold"
            style={{ color: color, backgroundColor: bg }}
          >
            {item.type.toUpperCase()}
          </div>
          {searchSplitTitleCase.map((splitVal, idx) => (
            <span key={splitVal + idx}>
              <span
                className={`whitespace-pre-wrap ${
                  active ? `text-grey-700` : `text-grey-500`
                }`}
              >
                {splitVal}
              </span>
              {idx < searchSplitTitleCase.length - 1 ? (
                <span className="text-grey-700">{values[idx]}</span>
              ) : null}
            </span>
          ))}
        </li>
      </Link>
    )
  }
)

const matchSearchTerm = (list: Array<itemType>, searchTerm: string) => {
  return list.filter(val =>
    val.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  onSubmit,
  suggestions,
  disabled,
}) => {
  const searchInput = useRef(null)
  const downPress = useKeyPress({
    targetKey: "ArrowDown",
    ref: searchInput,
    preventDefault: true,
  })
  const upPress = useKeyPress({
    targetKey: "ArrowUp",
    ref: searchInput,
    preventDefault: true,
  })
  const enterPress = useKeyPress({
    targetKey: "Enter",
    ref: searchInput,
  })
  const [cursor, setCursor] = useState<number>(0)
  const [hovered, setHovered] = useState<itemType | undefined>(undefined)
  const [searchValue, setSearchValue] = useState("")
  const [focused, setFocused] = useState(false)
  const [selected, setSelected] = useState<
    React.SetStateAction<itemType | undefined>
  >(undefined)
  const [items, setItems] = useState(matchSearchTerm(suggestions, searchValue))

  const handleSubmit = (item: itemType) => {
    navigate(item.link)
    // onSubmit(key)
    // setCursor(0)
    // setHovered(undefined)
    // setSelected(undefined)
    // setSearchValue("")
    // setItems(matchSearchTerm(suggestions, ""))
  }

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setItems(matchSearchTerm(suggestions, e.currentTarget.value))
    setCursor(0)
    setSelected(undefined)
    setSearchValue(e.currentTarget.value)
  }

  useEffect(() => {
    setItems(matchSearchTerm(suggestions, searchValue))
    setCursor(0)
  }, [suggestions])

  useEffect(() => {
    if (selected) {
      handleSubmit(selected)
    }
  }, [selected])

  useEffect(() => {
    if (items.length && downPress) {
      setCursor(prevState => (prevState < items.length - 1 ? prevState + 1 : 0))
    }
  }, [downPress])

  useEffect(() => {
    if (items.length && upPress) {
      setCursor(prevState => (prevState > 0 ? prevState - 1 : items.length - 1))
    }
  }, [upPress])

  useEffect(() => {
    if ((items.length && enterPress) || (items.length && hovered)) {
      handleSubmit(items[cursor])
    }
  }, [enterPress])

  useEffect(() => {
    if (items.length && hovered) {
      setCursor(
        items
          .map(e => {
            return e.id
          })
          .indexOf(hovered.id)
      )
    }
  }, [hovered])

  const itemsToShow = items.slice(0, 10)

  const open = searchValue && focused

  return (
    <div className="relative block w-full">
      <div
        className={`z-30 flex relative items-center w-full bg-grey-100 overflow-hidden border-box transition duration-300 ${
          open ? `rounded-t-3xl` : `rounded-full`
        }`}
        style={{
          boxShadow: `0px 1px 4px 1px rgba(0,0,0,0.12) ${
            focused && !open
              ? `, 0px 0px 2px 3px rgba(57, 202, 255, 0.7)`
              : `, 0px 0px 0px 0px rgba(57, 202, 255, 0.0)`
          }`,
        }}
      >
        <SearchIcon className="relative mx-5" />
        <input
          ref={searchInput}
          onChange={handleChange}
          value={searchValue}
          className={`flex-1 relative border-grey-150 bg-transparent placeholder-grey-400 h-12 text-grey-700 outline-none text-base`}
          placeholder={placeholder}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={disabled}
        />
      </div>
      {open ? (
        <div
          className={`absolute max-h-32 w-full z-30 bg-grey-100 px-4 rounded-b-3xl border-box ${
            focused ? `border-curiousblue` : `border-grey-100`
          }`}
          style={{
            marginTop: -2,
            boxShadow: `${focused ? `` : ``}0px 3px 4px 1px rgba(0,0,0,0.12) `,
          }}
        >
          <div className="border-grey-150 border-t py-2">
            {items.length ? (
              <ul role="listbox">
                {itemsToShow.map((item, i) => (
                  <ListItem
                    key={item.id}
                    active={i === cursor}
                    item={item}
                    setSelected={setSelected}
                    setHovered={setHovered}
                    searchValue={searchValue}
                  />
                ))}
              </ul>
            ) : (
              <h3 className="text-center block text-base text-grey-700 my-1">
                No results
              </h3>
            )}
          </div>
        </div>
      ) : null}
    </div>
  )
}
export default SearchBar
