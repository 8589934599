/**
 * Note: Requires a sorted input array
 * @param ranges
 * @param age
 */
export const findAgeBracket = (ranges: Array<number>, age: number): number => {
  if (!ranges.length) {
    return 0
  }

  let ageBracket = ranges[ranges.length - 1]
  for (let i = ranges.length - 1; i >= 0; i--) {
    if (age > ranges[i]) {
      break
    }
    ageBracket = ranges[i]
  }

  return ageBracket
}

/**
 * Note: Requires a sorted input array
 * @param ranges
 * @param age
 */
export const findAgeBracketWithSexes = (
  ranges: Array<[number, string | null]>,
  metrics: Metrics
): number => {
  if (!ranges.length) {
    return 0
  }

  // Gets the lowest age that is less than metrics.age and matches the sex
  let ageBracket = ranges.length - 1
  for (let i = ranges.length - 1; i >= 0; i--) {
    if (metrics.age >= ranges[i][0]) {
      ageBracket = i
      break
    }
    // Uses first letter of sex as the match criteria (ie female = f)
    if (ranges[i][1] === null || metrics.sex[0] === ranges[i][1]) {
      ageBracket = i
    }
  }

  return ageBracket
}
