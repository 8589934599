import React from "react"
import ExitCross from "../../images/svgs/buttons/exitCross.svg"

interface CrossButtonProps {
  onClick: () => void
}

const CrossButton: React.FC<CrossButtonProps> = ({ onClick }) => {
  return (
    <button
      className="absolute focus-visible group"
      style={{ top: 15, right: 15 }}
      onClick={() => onClick()}
    >
      <ExitCross className="fill-current group-hover:text-grey-400 text-grey-200 transition duration-200" />
    </button>
  )
}
export default CrossButton
