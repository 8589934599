import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import reducer from "./reducers"

const createStore = () =>
  configureStore({
    reducer: reducer,
  })
export default createStore
const tempStore = createStore()
export type RootState = ReturnType<typeof tempStore.getState>
export type AppDispatch = typeof tempStore.dispatch
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
